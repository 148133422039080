import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Headline, StyledHeadlineWrapper } from "../components/headline"
import SEO from "../components/seo"
import { PageSwiper } from "../components/pageSlider"
import VierSwiper from "../components/vierSwiper"
import { Columns } from "../components/columns"
import SingleImage from "../components/singleImage"
import { Services } from "../components/services"
import { Contact } from "../components/contact"
import styled from "styled-components"

const StyledText = styled.div`
 margin-bottom: 100px;
`
const swiperparams = {
  autoHeight: true,
  navigation: true,
}

const Content = ({ hasColumns, content }) => {
  if (hasColumns !== false) {
    return (
      <Columns content={content}/>
    )
  }
  return (
    <StyledText dangerouslySetInnerHTML={{ __html: content }}/>
  )
}

export default ({ data }) => (
  <Layout>
    <SEO title={data.wpAcfLeistung.title}/>
    <div className={"container"}>
      <div className={"row justify-content-center"}>
        <div className={"col-10"}>
          <StyledHeadlineWrapper>
            <Headline
              headlineH1={data.wpAcfLeistung.title}
            />
          </StyledHeadlineWrapper>
        </div>
      </div>
    </div>

    {data.wpAcfLeistung.acfLeistungenPosts.fotos && data.wpAcfLeistung.acfLeistungenPosts.fotos.length > 1 ?
      <VierSwiper params={swiperparams} items={data.wpAcfLeistung.acfLeistungenPosts.fotos}/> :
      <SingleImage image={data.wpAcfLeistung.featuredImage.node.localFile}/>}

    <div className={"container"}>
      <div className={"row justify-content-center"}>
        <div className={"col-10"}>
          <Content hasColumns={data.wpAcfLeistung.acfLeistungenPosts.columns} content={data.wpAcfLeistung.content}/>
        </div>
      </div>
    </div>
    <Services parent={data.wpAcfLeistung.slug}/>
    <PageSwiper list={data.allWpAcfProjekt.nodes} title={data.wpAcfLeistung.title}/>
    <Contact/>
  </Layout>
)

export const query = graphql`
    query (
        $slug: String!
    ) {
        wpAcfLeistung(slug: {eq: $slug}) {
            date(formatString: "MMMM Do, YYYY")
            title
            content
            slug
            featuredImage {
                node {
                    localFile {
                        childImageSharp {
                            fluid(
                                maxWidth: 1200,
                                maxHeight: 800,
                                quality: 90,
                                cropFocus: CENTER
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
            acfLeistungenPosts {
                columns
                fotos {
                    localFile {
                        childImageSharp {
                            fluid(
                                maxWidth: 1600,
                                quality: 80
                            ) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }

        allWpAcfProjekt {
            nodes {
                title
                slug
                link
                contentType {
                    node {
                        name
                    }
                }
                featuredImage {
                    node {
                        localFile {
                            childImageSharp {
                                fluid(
                                    maxWidth: 360,
                                    maxHeight: 200,
                                    quality: 75,
                                    cropFocus: CENTER
                                ) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
